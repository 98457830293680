import React from "react";
import Title from "../components/Title";
import WideSection from "../components/WideSection";
import image from "../assets/images/aboutUsPhotoPathUtility.jpg";
import "./about.scss";

/** About Us Section */
const About = () => {
  return (
    <WideSection direction="row" id="about">
      <section className="about">
        <section className="aboutContent">
          <Title restOfTitle="Path Utility" firstWord="About" />
          <p>
            Path Utility was founded in 2001 by Northwest Arkansas native Craig
            Graves, to serve the communication industry. In 17 years’ time, Path
            Utility has provided comprehensive utility construction services to
            businesses throughout many states. Simply put, Path Utility
            specializes in servicing utility corporations, municipalities, state
            and commercial industries with the utmost convenience and service.
          </p>
        </section>
        <img src={image} alt="About Path Utility Construction" />
      </section>
    </WideSection>
  );
};

export default About;
