import React from "react";

/** Footer Component */
const Footer = () => {
  return (
    <section className="footer">
      <p>PATH UTILITY CONSTRUCTION</p>
      <section className="icon-set">
        <i className="fas fa-map-marker-alt" />
        <a
          href="https://www.google.com/maps/place/2785+Liberty+Ave,+Springdale,+AR+72762/@36.1827943,-94.2844547,17z"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>2785 Liberty Ave</p>
          <p>Springdale, AR 72762</p>
        </a>
      </section>
      <section className="icon-set">
        <i className="fa fa-phone fa-flip-horizontal" aria-hidden="true" />
        <a href="tel:4793657049">479.365.7049</a>
      </section>
      <section className="icon-set">
        <i className="far fa-envelope" />
        <a href="mailto:craig@pathutility.com">Office@PathUtility.com</a>
      </section>
    </section>
  );
};

export default Footer;
