import React from "react";
import Slider from "../components/Slider";

/** Hero Section */
const Hero = () => {
  return (
    <section className="hero">
      <Slider />
    </section>
  );
};

export default Hero;
