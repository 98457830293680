import React from "react";
import PropTypes from "prop-types";

/** Title Component with accent word */
const Title = ({ firstWord, restOfTitle }) => {
  return (
    <section className="title">
      <h2>
        <span>{firstWord}</span>
        <span>{restOfTitle}</span>
        </h2>
        <div className="title-underline"/>
    </section>
  );
};

Title.propTypes = {
  firstWord: PropTypes.string.isRequired,
  restOfTitle: PropTypes.string.isRequired
};

export default Title;
