import React from "react";
import Title from "../components/Title";
import WideSection from "../components/WideSection";
import GoogleMap from "../components/GoogleMap";
import "./contact.scss";

/** Contact Us Section */
const Contact = () => {
  return (
    <WideSection direction="column" id="contact">
      <Title firstWord="Contact" restOfTitle="Us" />
      <section className="contact">
        <section className="contact-links">
          <section className="link-set">
            <i className="fas fa-map-marker-alt" />
            <a
              href="https://www.google.com/maps/place/4651+W+Mt+Comfort+Rd,+Fayetteville,+AR+72704"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>2785 Liberty Ave</p>
              <p>Springdale, AR 72762</p>
            </a>
          </section>
          <section className="link-set">
            <i className="fas fa-phone fa-flip-horizontal" />
            <a href="tel:479-316-8144">479.316.8144</a>
          </section>
          <section className="link-set">
            <i className="far fa-envelope" />
            <a href="mailto:Craig@PathUtility.com">Office@PathUtility.com</a>
          </section>
        </section>
        <section className="google-map">
          <GoogleMap />
        </section>
      </section>
    </WideSection>
  );
};

export default Contact;
