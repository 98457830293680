import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Slider from "react-slick";
import slide1 from "../../assets/images/slider/servicesAerialPathUtility.jpg";
import slide2 from "../../assets/images/slider/servicesCommercialDataPlacement.jpg";
import slide3 from "../../assets/images/slider/servicesDirectionalBoringPathUtility.jpg";
import slide4 from "../../assets/images/slider/servicesExcavationPathUtility.jpg";
import slide5 from "../../assets/images/slider/servicesFiberSplicing.jpg";

const slides = [
  { img: slide1, title: "Aerial", path: "#services" },
  { img: slide2, title: "Commercial Data Placement", path: "#services" },
  { img: slide3, title: "Directional Boring", path: "#services" },
  { img: slide4, title: "Excavation", path: "#services" },
  { img: slide5, title: "Fiber Splicing", path: "#services" }
];

/** Hero Slider */
export default class PathSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 8000,
      cssEase: "linear",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div className="slider">
        <Slider {...settings}>
          {slides.map(slide => {
            return (
              <div key={slide.title} className="slider-single-slide">
                <img src={slide.img} alt="" />
                <section className="image-overlay" />
                <section className="slide-content">
                  <h1>{slide.title}</h1>
                  <Link to="/#services">Read More</Link>
                </section>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={`arrow prevArrow`} onClick={onClick}>
      <i className="fas fa-angle-left" />
    </div>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={`arrow nextArrow`} onClick={onClick}>
      <i className="fas fa-angle-right" />
    </div>
  );
}
