import React from "react";
import PropTypes from "prop-types";

/** Small Image and Text Boxes for the Service Page */
const ServiceBox = ({ image, alt, title, content }) => {
  return (
    <section className="service-box">
      <img src={image} alt={alt} />
      <section className="service-box-content">
        <h4>{title}</h4>
        <p>{content}</p>
      </section>
    </section>
  );
};

ServiceBox.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default ServiceBox;
