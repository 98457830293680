import React from "react";
import Title from "../components/Title";
import WideSection from "../components/WideSection";
import ServiceBox from "../components/ServiceBox";
import aerialImage from "../assets/images/services/servicesAerialPathUtility.jpg";
import commercialImage from "../assets/images/services/servicesCommercialDataPlacement.jpg";
import directionImage from "../assets/images/services/servicesDirectionalBoringPathUtility.jpg";
import excavationImage from "../assets/images/services/servicesExcavationPathUtility.jpg";
import fiberImage from "../assets/images/services/servicesFiberSplicing.jpg";
import "./services.scss";

/** Services Section */
const Services = () => {
  return (
    <WideSection direction="column" backgroundColor="#e6e6e6" id="services">
      <Title firstWord="Our" restOfTitle="Services" />
      <section className="services">
        <ServiceBox
          image={aerialImage}
          alt="Aerial"
          title="Aerial"
          content="Path Utility’s Aerial division is available 24/7. We possess a full fleet of aerial equipment for construction and maintenance. Our aerial crews provide full service communication line installations and maintenance. We are available 24 hours a day, 7 days a week for emergency services and storm repair."
        />
        <ServiceBox
          image={commercialImage}
          alt="Commercial Data Placement"
          title="Commercial Data Placement"
          content="At Path Utility, our professional cable installers and technicians have many years of experience in commercial computer data cable installation, communication line installation, high speed internet and audio video cable installation. We can install a wide variety of transmission media including copper, fiber optic, twisted pair, and well as associated components. We also install a variety of wireless applications including 802.11a, 802.11b, 802.11g cameras, WiFi VoIP."
        />
        <ServiceBox
          image={fiberImage}
          alt="Fiber Splicing"
          title="Fiber Splicing"
          content="Need fiber optic services? We provide fiber optic testing, fiber optic telephone services, fiber optic CATV services, fiber optic repair services, fiber maintenance, testing, storm damage restoration and troubleshooting services. Customer satisfaction is our number one priority. We use only the very best equipment to give the customer a variety of specialized fiber optic testing, maintenance and installation services. Our technicians and contractors have years of experience and will be able to troubleshoot your needs in no time."
        />
        <ServiceBox
          image={excavationImage}
          alt="Excavation"
          title="Excavation"
          content="Path Utility specializes in underground cable placement. Path Utility provides turn-key fiber placement for communication companies. We also specialize in the installation of underground electrical cable for utility companies. We have completed plowing, directional boring, trenching, cable pulling, and fiber splicing for our customers. Path Utility has the capability to do directional boring from 2″ up to 60″."
        />
        <ServiceBox
          image={directionImage}
          alt="Directional Boring"
          title="Directional Boring"
          content="Directional boring is used when trenching or excavating is not practical. It is suitable for a variety of soil conditions and jobs including road, landscape and river crossings. The process is used for installing telecommunications & power cable conduits, water lines, sewer lines, gas lines. It is used for crossing environmentally sensitive areas and any area where other methods are more expensive or impractical."
        />
      </section>
    </WideSection>
  );
};

export default Services;
