import React from "react";
import PropTypes from "prop-types";

/** HOC for spacing of sections */
const WideSection = ({ children, direction, backgroundColor, id }) => {
  return (
    <section
      id={id}
      className={`wideSection-${direction}`}
      style={{ backgroundColor }}
    >
      {children}
    </section>
  );
};

WideSection.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["row", "column"]),
  backgroundColor: PropTypes.string,
  id: PropTypes.string
};

WideSection.defaultProps = {
  backgroundColor: "#fff",
  id: ""
};

export default WideSection;
