import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/LOGO_NEW_Path_Utility.png";

/** Header component */
const Header = () => {
  return (
    <section className="header">
      <section className="header-left">
        <section className="image-wrapper">
          <img src={logo} alt="Welcome to Path Utility Construction" />
        </section>
      </section>
      <section className="header-right">
        <section className="header-right-upper">
          <section className="header-right-upper-left">
            <a href="mailto:info@pathutility.com">
              <i className="far fa-envelope" />info@pathutility.com
            </a>
            <a href="tel:4793657049">
              <i
                className="fa fa-phone fa-flip-horizontal"
                aria-hidden="true"
              />
              1.479.365.7049
            </a>
          </section>
          <section className="header-right-upper-right">
            <a
              href=" https://www.facebook.com/Pathutilityconstruction/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://www.google.com/maps/uv?hl=en&pb=!1s0x87c96e0488765bcd:0x41e85736cfe5f503!2m22!2m2!1i80!2i80!3m1!2i20!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e5!2m2!1m1!1e4!2m2!1m1!1e6!3m1!7e115!4shttps://lh5.googleusercontent.com/p/AF1QipNOgApxh39v8gtE0aQkhJF9shp5a2D2ptsbUHf7%3Dw160-h160-k-no!5spath+utility+construction+fayetteville,+ar+-+Google+Search&imagekey=!1e10!2sAF1QipNOgApxh39v8gtE0aQkhJF9shp5a2D2ptsbUHf7&sa=X&ved=2ahUKEwixxcHjmoHeAhUF7awKHUTJBgAQoiowCnoECAoQCQ">
              <i className="fab fa-google" />
            </a>
            <a
              href="https://www.instagram.com/pathutility.com_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
          </section>
        </section>
        <section className="header-right-lower">
          <ul>
            <Link to="/" className="active-link">
              <li>Home</li>
            </Link>
            <Link to="/#about">
              <li>Who We Are</li>
            </Link>
            <Link to="/#services">
              <li>Services</li>
            </Link>
            <Link to="/#contact">
              <li>Contact Us</li>
            </Link>
          </ul>
        </section>
      </section>
    </section>
  );
}

export default Header;
