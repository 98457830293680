import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

/** Google Map Component */
const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA-FbLDvMI5qV6sGQ6Dv1t3lx-YgD4k9p4",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(() => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 36.1841732, lng: -94.2844292 }}
    mapTypeControl={false}
    defaultOptions={{
      streetViewControl: false,
      mapTypeControl: false,
      styles: [{ color: "#99FF33" }]
    }}
  >
    {
      <Marker
        position={{ lat: 36.1841732, lng: -94.2844292 }}
        onClick={() => {
          window.location.href =
            "https://www.google.com/maps/place/Path+Utility+Construction/@36.1841732,-94.2844292,17z/data=!3m1!4b1!4m5!3m4!1s0x87c96e0488765bcd:0x41e85736cfe5f503!8m2!3d36.1841734!4d-94.2822352";
        }}
      />
    }
  </GoogleMap>
));

export default MyMapComponent;
